<template>
  <div class="report">
  <div class="group">
    <div class="info">
      <label>Total Referrals: </label>
      <span>{{userReport && userReport.totalRef}}</span>
    </div>
    <div class="info">
      <label>Total open egg: </label>
      <span>{{userReport && userReport.totalOpenEgg}}</span>
    </div>
      <div class="info">
      <label>Total ref rw: </label>
      <span>{{userReport && userReport.totalRefRw}}</span>
    </div>
    <div class="info">
      <label>Rw egg: </label>
      <span>{{userReport && userReport.totalRwEgg}}</span>
    </div>
        <div class="info">
      <label>Rw combat: </label>
      <span>{{userReport && userReport.totalRwCombat}}</span>
    </div>
    <div class="line" ></div>
    <div class="user-by-tier"  v-for="(_item, index) in userReport.totalUserByTier" :key="index">
      <div class="info">
      <label>{{`Total User Tier ${_item.tier} :`}}</label>
      <span>{{_item && _item.total}}</span>
      </div>
      <div class="total-egg">Total open egg: {{_item.totalOpenEgg}}</div>
    </div>
    <div class="line" ></div>
    <div class="user-by-tier"  v-for="(item, index1) in userReport.totalRwList" :key="index1">
      <div class="info">
      <label>{{`Total RW Tier ${item.tier} :`}}</label>
      <span>{{item && item.totalRewards}}</span>
      </div>
      <div class="total-egg" v-for="(detail, indexDetail) in item.detail" :key="indexDetail">{{detail.type === 'BUY-EGG' ? 'Rw egg' : 'Rw combat'}}: {{detail.totalRewards}}</div>
    </div>
  </div>
  </div>
</template>
<script>
export default {
  name: 'PlantRefReport',
  computed: {
    userReport() {
      return this.$store.getters.userReport
    }
  },
  created () {
    this.$store.dispatch('getUserReport')
  }
}
</script>
<style lang="scss" scoped>
  .report {
    padding: 20px;
    max-width: 600px;
    margin: 0 auto;
    .line {
      width: 100%;
      height: 1px;
      background: gray;
      margin: 5px 0;
    }
    .total-egg {
      margin-left: 20px;
        font-style: italic;
    }
    .group, .report-map {
      padding: 10px;
    }
    .info {
      display: flex;
    }
    .map-name {
      font-size: 24px;
    }
    label {
      min-width: 200px;
      font-size: 20px;
    }
    span {
      font-weight: bold;
      font-size: 20px;
      width: 100%;
      text-align: right;
    }
  }
  </style>